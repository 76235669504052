import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WithAuth } from "../../components/with-auth";
import MainWrapper from "../../layout/MainWrapper";
import { createAxiosInstance } from "../../api/ApiRequest";
import { Col, Row, Card, ConfigProvider, Tabs } from 'antd';
import { ReloadOutlined, AndroidOutlined } from '@ant-design/icons';
import { notifyError } from '../../utils/toast';

interface CoinData {
    token_name: string;
    username: string;
    firstname: string;
    lastname: string;
    pool_id: string;
    pool_type: string;
    token_address: string;
    dex: string;
    txns: { m5?: { buys: number, sells: number } };
    priceNative?: string;
    priceUsd?: string;
    volume: { m5?: number };
    priceChange: { m5?: number };
    marketCap?: number;
    liquidity: { usd?: number };
    fdv?: number;
    speed?: number; // only volume token
    volume_mode?: number; // only volume token
    ranking_server_number?: number; // only ranking token
    rank_number?: number; // only ranking token
    holder_num_times?: number; // only holder token
}

interface ApiResponse {
    flag: boolean;
    tokens: CoinData[];
    totalNum: number;
    message?: string;
}

interface LoadingState {
    volume: boolean;
    rank: boolean;
    holder: boolean;
    pumpfun: boolean;
    moonshotRank: boolean;
    moonshotVolume: boolean;
}

const ActiveCoin: React.FC = () => {
    const history = useNavigate();
    const axiosInstance = createAxiosInstance(history);
    const intervalTime = 120000; // 120s = 2min

    const [activeVolumeCoins, setActiveVolumeCoins] = useState<CoinData[]>([]);
    const [activeRankCoins, setActiveRankCoins] = useState<CoinData[]>([]);
    const [activeHolderCoins, setActiveHolderCoins] = useState<CoinData[]>([]);
    const [activePumpfunCoins, setActivePumpfunCoins] = useState<CoinData[]>([]);
    const [activeMoonshotRankCoins, setActiveMoonshotRankCoins] = useState<CoinData[]>([]);
    const [activeMoonshotVolumeCoins, setActiveMoonshotVolumeCoins] = useState<CoinData[]>([]);

    const [fetchTime, setFetchTime] = useState<Boolean>(false);

    const [coinNumbers, setCoinNumbers] = useState({
        volume: 0,
        rank: 0,
        holder: 0,
        pumpfun: 0,
        moonshotRank: 0,
        moonshotVolume: 0
    });

    const [loadingState, setLoadingState] = useState<LoadingState>({
        volume: true,
        rank: true,
        holder: true,
        pumpfun: true,
        moonshotRank: true,
        moonshotVolume: true
    });

    // Function to fetch data based on type
    const fetchData = async (endpoint: string, type: keyof LoadingState) => {
        try {
            const results = await axiosInstance.post<ApiResponse>(endpoint);
            if (results.data && results.data.flag) {
                setLoadingState(prev => ({ ...prev, [type]: false }));
                if (type === 'volume') setActiveVolumeCoins(results.data.tokens);
                if (type === 'rank') setActiveRankCoins(results.data.tokens);
                if (type === 'holder') setActiveHolderCoins(results.data.tokens);
                if (type === 'pumpfun') setActivePumpfunCoins(results.data.tokens);
                if (type === 'moonshotRank') setActiveMoonshotRankCoins(results.data.tokens);
                if (type === 'moonshotVolume') setActiveMoonshotVolumeCoins(results.data.tokens);
                setCoinNumbers(prev => ({ ...prev, [type]: results.data.totalNum }));
            } else {
                notifyError(results.data.message || 'An error occurred');
                localStorage.removeItem('token');
                history('/login');
            }
        } catch (error) {
            notifyError('An error occurred while fetching data');
        }
    };

    // Reload individual sections on click
    const handleReload = (type: keyof LoadingState) => {
        setLoadingState(prev => ({ ...prev, [type]: true }));
        if (type === 'volume') fetchData('/api/v1/get_active_volume', 'volume');
        if (type === 'rank') fetchData('/api/v1/get_active_rank', 'rank');
        if (type === 'holder') fetchData('/api/v1/get_active_holder', 'holder');
        if (type === 'pumpfun') fetchData('/api/v1/get_active_pumpfun', 'pumpfun');
        if (type === 'moonshotRank') fetchData('/api/v1/get_active_moonshotRank', 'moonshotRank');
        if (type === 'moonshotVolume') fetchData('/api/v1/get_active_moonshotVolume', 'moonshotVolume');
    };

    useEffect(() => {
        setLoadingState(prev => ({ ...prev, volume: true }));
        setLoadingState(prev => ({ ...prev, rank: true }));
        setLoadingState(prev => ({ ...prev, holder: true }));
        setLoadingState(prev => ({ ...prev, pumpfun: true }));
        setLoadingState(prev => ({ ...prev, moonshotRank: true }));
        setLoadingState(prev => ({ ...prev, moonshotVolume: true }));
        fetchData('/api/v1/get_active_volume', 'volume');
        fetchData('/api/v1/get_active_rank', 'rank');
        fetchData('/api/v1/get_active_holder', 'holder');
        fetchData('/api/v1/get_active_pumpfun', 'pumpfun');
        fetchData('/api/v1/get_active_moonshotRank', 'moonshotRank');
        fetchData('/api/v1/get_active_moonshotVolume', 'moonshotVolume');
        setTimeout(() => {
            setFetchTime(!fetchTime);
        }, intervalTime);
    }, [fetchTime]);

    const renderCoinCards = (coins: CoinData[], title: string, type: keyof LoadingState) => (
        <Col span={8}>
            <span className="my-span">
                {title}: {coinNumbers[type]} 
                <a href="#" style={{ float: "right", marginRight: "30px" }} title="reload" onClick={() => handleReload(type)}>
                    <ReloadOutlined />
                </a>
            </span>
            <Row className="my-row">
                <Col span={23} className="scrollable-container">
                    <LoadingCard title={title} loadingState={loadingState} />
                    {coins.map((coin, index) => (
                        <CoinCard key={index} coin={coin} type={type} loadingState={loadingState} />
                    ))}
                </Col>
            </Row>
        </Col>
    );

    const renderTab1Content = () => (
        <ConfigProvider
            theme={{
                components: {
                    Card: {
                        headerBg: '#e6f4ff',
                        headerFontSize: 16,
                        headerFontSizeSM: 10,
                        headerHeight: 40,
                        headerHeightSM: 60,
                        actionsBg: '#e6f4ff',
                        actionsLiMargin: `0 0`,
                        tabsMarginBottom: -10,
                        extraColor: 'rgba(0,0,0,0.25)',
                    },
                },
                token: {
                    padding: 10,
                    paddingLG: 10,
                }
            }}
        >
            <Row>
                {renderCoinCards(activeVolumeCoins, "Volume Boost Coin", 'volume')}
                {renderCoinCards(activeRankCoins, "Rank Boost Coin", 'rank')}
                {renderCoinCards(activeHolderCoins, "Holder Boost Coin", 'holder')}
            </Row>
        </ConfigProvider>
    );

    const renderTab2Content = () => (
        <ConfigProvider
            theme={{
                components: {
                    Card: {
                        headerBg: '#e6f4ff',
                        headerFontSize: 16,
                        headerFontSizeSM: 10,
                        headerHeight: 40,
                        headerHeightSM: 60,
                        actionsBg: '#e6f4ff',
                        actionsLiMargin: `0 0`,
                        tabsMarginBottom: -10,
                        extraColor: 'rgba(0,0,0,0.25)',
                    },
                },
                token: {
                    padding: 10,
                    paddingLG: 10,
                }
            }}
        >
            <Row>
                {renderCoinCards(activePumpfunCoins, "Pump.fun Bump Coin", 'pumpfun')}
                {renderCoinCards(activeMoonshotRankCoins, "Moonshot Rank/Bump Coin", 'moonshotRank')}
                {renderCoinCards(activeMoonshotVolumeCoins, "Moonshot Volume/Bump Coin", 'moonshotVolume')}
            </Row>
        </ConfigProvider>
    );

    const items = [
        {
            key: '1',
            label: 'Tab 1',
            icon: <AndroidOutlined />,
            children: renderTab1Content()
        },
        {
            key: '2',
            label: 'Tab 2',
            icon: <AndroidOutlined />,
            children: renderTab2Content()
        }
    ];

    return (
        <WithAuth>
            <MainWrapper>
                <Tabs 
                    defaultActiveKey="1"
                    items={items}
                />
            </MainWrapper>
        </WithAuth>
    );
};

interface LoadingCardProps {
    title: string;
    loadingState: LoadingState;
}

const LoadingCard: React.FC<LoadingCardProps> = ({ title, loadingState }) => {
    const getLoadingState = () => {
        if (title.includes('Pump.fun')) return loadingState.pumpfun;
        else if (title.includes('Moonshot Rank/Bump')) return loadingState.moonshotRank;
        else if (title.includes('Moonshot Volume/Bump')) return loadingState.moonshotVolume;
        else if (title.includes('Volume')) return loadingState.volume;
        else if (title.includes('Rank')) return loadingState.rank;
        return loadingState.holder;
    };

    const isLoading = getLoadingState();

    return (
        <Card 
            loading={isLoading} 
            className="my-card" 
            style={{ display: isLoading ? 'block' : 'none' }}
        />
    );
};

interface CoinCardProps {
    coin: CoinData;
    type: keyof LoadingState;
    loadingState: LoadingState;
}

const CoinCard: React.FC<CoinCardProps> = ({ coin, type, loadingState }) => {
    const getLoadingState = () => {
        if (type == 'pumpfun') return loadingState.pumpfun;
        else if (type == 'moonshotRank') return loadingState.moonshotRank;
        else if (type == 'moonshotVolume') return loadingState.moonshotVolume;
        else if (type === 'volume') return loadingState.volume;
        else if (type === 'rank') return loadingState.rank;
        return loadingState.holder;
    };

    const coin_rank_number = typeof(coin.rank_number) != 'undefined'? (coin.rank_number > 0? String(coin.rank_number): 'XX'): ''; 

    const isLoading = getLoadingState();

    return (
        <Card 
            title={`${coin.token_name} ${coin.username.length > 0 ? ' ( @' + coin.username + ' )' : ''}`} 
            bordered={true} 
            className="my-card"
            style={{ display: isLoading ? 'none' : 'block' }}
        >
            <Row className="my-row">
                <Col span={12}>
                    <p className="my-paragraph">
                        token: &nbsp;
                        {
                            type.includes('moonshot')? (
                                <>
                                    <a href={`https://dexscreener.com/solana/${coin.token_address}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                        <b>{coin.token_name}</b>
                                    </a>
                                </>
                            ): type.includes('pumpfun')? (
                                <b>{coin.token_address}</b>
                            ): (
                                <a href={`https://dexscreener.com/solana/${coin.pool_id}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                    <b>{coin.token_name}</b>
                                </a>
                            )
                        }
                    </p>
                </Col>
                <Col span={12}>
                    <p className="my-paragraph">
                        Telegram User ID: &nbsp;
                        <a href={`https://t.me/${coin.username}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                            <b>{coin.username}</b>
                        </a>
                    </p>
                </Col>
            </Row>
            {coin.pool_id && <p className="my-paragraph">pool_id: <b>{coin.pool_id}</b></p>}
            <Row className="my-row">
                <Col span={10}>
                    <p className="my-paragraph">firstname: <b>{coin.firstname}</b></p>
                    <p className="my-paragraph">lastname: <b>{coin.lastname}</b></p>
                    <p className="my-paragraph">dex: <b>{coin.dex}</b></p>
                    {coin.pool_type && <p className="my-paragraph">pool_type: <b>{coin.pool_type}</b></p>}
                    {coin.holder_num_times && <p className="my-paragraph">holder_num_times: <b>{coin.holder_num_times}</b></p>}
                    {coin.speed && <p className="my-paragraph">speed: <b>{coin.speed}</b></p>}
                    {coin.volume_mode && <p className="my-paragraph">volume_mode: <b>{coin.volume_mode}</b></p>}
                    {coin.ranking_server_number && <p className="my-paragraph">ranking_server_number: <b>{coin.ranking_server_number}</b></p>}
                    {coin_rank_number.length > 0 && <p className="my-paragraph">rank_number: <b>{coin_rank_number}</b></p>}
                </Col>
                <Col span={2}></Col>
                <Col span={10}>
                    {coin.priceUsd && <p className="my-paragraph">PRICE USD: <b>{coin.priceUsd}</b></p>}
                    {/* {coin.priceNative && <p className="my-paragraph">PRICE: <b>{coin.priceNative + ' SOL'}</b></p>} */}
                    {coin.liquidity?.usd && <p className="my-paragraph">LIQUIDITY: <b>{Math.floor(coin.liquidity.usd / 1000) + 'K'}</b></p>}
                    {/* {coin.fdv && <p className="my-paragraph">FDV: <b>{Math.floor(coin.fdv/1000) + 'K'}</b></p>} */}
                    {coin.marketCap && <p className="my-paragraph">MKT CAP: <b>{Math.floor(coin.marketCap/1000) + 'K'}</b></p>}
                    {coin.txns?.m5 && (
                        <>
                            <p className="my-paragraph">5M BUYS: <b>{coin.txns.m5.buys}</b></p>
                            <p className="my-paragraph">5M SELLS: <b>{coin.txns.m5.sells}</b></p>
                        </>
                    )}
                    {coin.txns?.m5 && type !== 'rank' && <p className="my-paragraph">5M TXs: <b>{coin.txns.m5.buys + coin.txns.m5.sells}</b></p>}
                    {coin.txns?.m5 && type === 'rank' && <p className="my-paragraph active-p">5M TXs: <b>{coin.txns.m5.buys + coin.txns.m5.sells}</b></p>}
                    {typeof(coin.volume?.m5) != 'undefined' && type !== 'volume' && <p className="my-paragraph">5M VOLUME: <b>${coin.volume.m5}</b></p>}
                    {typeof(coin.volume?.m5) != 'undefined' && type === 'volume' && <p className="my-paragraph active-p">5M VOLUME: <b>${coin.volume.m5}</b></p>}
                    {/* {coin.priceChange?.m5 && <p className="my-paragraph">5M PRICE CHANGE: <b>${coin.priceChange.m5}</b></p>} */}
                </Col>
            </Row>
        </Card>
    );
};

export default ActiveCoin;
